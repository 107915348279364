.account-title-background {
  background-color: #00004d;
  color: #ff5757;
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 10%;
  padding-right: 10%;
}

.texte {
  font-size: 35px;
  color: white;
}

.background-2 {
  font-family: "poppins";
  font-size: 60px;
  color: #00004d;
  padding-top: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  max-width: 100%;
  line-height: 90%;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-3 {
  font-family: "poppins";
  font-size: 60px;
  color: #00004d;
  padding-top: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  max-width: 100%;
  line-height: 90%;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.account-row {
  background-color: #ff5757;
  color: white;
}

.green-bloc {
  background-image: url("./Images/green.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 800px;
  margin-left: 0px;
  padding-left: 0px;
}

.account-col-1 {
  padding-left: 10%;
  padding-top: 10%;
  padding-bottom: 10%;
}

.info-profile {
  font-size: 40px;
  font-weight: 50;
  line-height: 40px;
  color: #f3f3f3;
}

.title-profile {
  font-size: 80px;
  margin-bottom: 50px;
  font-weight: 700;
  color: #00004d;
}
