@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-SemiBold.ttf) format("truetype");
}

h1 {
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 30px;
}

.h1-blue {
  color: white;
}

.h1-orange {
  color: #ff5757;
}

.background-slider {
  background-image: url("./Images/Logo_sponsors/marseille.jpeg");
  height: 100vh !important;
  font-family: "poppins";
  font-size: 60px;
  color: #00004d;
  padding-top: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  max-width: 100%;
  line-height: 90%;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title-background {
  background-color: rgba(255, 255, 255, 0.5);
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 40px;
  padding-bottom: 40px;
}

.icon {
  font-size: 60px;
  color: white;
}

.icon-2 {
  font-size: 80px;
  margin-right: 30px;
}

.icon-row {
  background-color: #00004d;
}

.icon-row-2 {
  background-color: white;
}

.icon-text {
  color: white;
  font-size: 24px;
  font-weight: 400;
  padding-top: 28px !important;
}

.icon-bloc {
  margin-left: 0px;
  margin-right: 0px;
  max-width: 100%;
  height: 800px;
}

.icon-bloc-1 {
  padding-left: 5%;
  padding-right: 5%;
}

.icon-bloc-2 {
  background-image: url("./Images/tramway-marseille.jpeg");
  background-size: cover;
}

.icon-bloc-3 {
  background-image: url("./Images/velib.jpeg");
  background-size: cover;
}

.icon-bloc-4 {
  color: #ff5757;
  padding-left: 5%;
  padding-right: 5%;
}

.bloc-transport {
  text-align: left;
  font-size: 24px;
}

.icon-velo {
  font-size: 90px;
}

.counter-box {
  display: block;
  background: white;
  border-radius: 50%;
  height: 250px;
  width: 250px;
  padding-top: 80px;
  text-align: center;
}

.colored-1 {
  border: solid 5px #0282c8;
}

.colored-2 {
  border: solid 5px rgb(255, 208, 0);
}

.colored-3 {
  border: solid 5px #00a651;
}

.colored-4 {
  border: solid 5px #ed354f;
}

.counter-box p {
  margin: 25px 25px 25px 25px;
  padding: 0;
  color: black;
  font-size: 18px;
  font-weight: 500;
}

.counter-box i {
  font-size: 60px;
  margin: 0 0 15px;
  color: black;
}

.counter {
  display: block;
  font-size: 32px;
  font-weight: 700;
  color: black;
  line-height: 28px;
}

.counter-box.colored {
  background: #3acf87;
}

.counter-box.colored p,
.counter-box.colored i,
.counter-box.colored .counter {
  color: #fff;
}

.stat-container {
  margin-top: 100px;
  margin-bottom: 100px;
}
