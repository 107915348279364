.size_footer {
  margin: 5px;
  padding: 5px;
  height: 6vh;
  object-fit: cover;
  align-items: center;
}
.footer_div {
  border-top: solid 2px #00004d;
  padding-top: 50px;
  padding-bottom: 50px;
}
